import { useEffect } from "react";

const ChatwootWidget = () => {
  useEffect(() => {
    window.chatwootSettings = {
      position: "right",
      type: "expanded_bubble",
      launcherTitle: "Live Chat",
    };

    const BASE_URL = process.env.REACT_APP_CHATWOOT_BASE_URL || "https://app.chatwoot.com";
    const websiteToken = process.env.REACT_APP_CHATWOOT_WEBSITE_TOKEN || "XGazqoa2Yom4Jiwub8QgGcYH";
    const chatwootScript = document.createElement("script");

    chatwootScript.src = `${BASE_URL}/packs/js/sdk.js`;
    chatwootScript.defer = true;
    chatwootScript.async = true;

    document.body.appendChild(chatwootScript);

    chatwootScript.onload = () => {
      window.chatwootSDK.run({
        websiteToken: websiteToken,
        baseUrl: BASE_URL,
      });
    };

    return () => {
      document.body.removeChild(chatwootScript);
    };
  }, []);

  return null;
};

export default ChatwootWidget;