import React from 'react';
import './App.css';
import ChatwootWidget from './ChatwootWidget';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        {/* Your App content */}
      </header>
      <ChatwootWidget />
    </div>
  );
}

export default App;